import { Injectable } from "@angular/core";
import { Client } from "../../interfaces/cliente-interface";
import { Observable } from "rxjs";
import { ActiveClientsInterface } from "../../interfaces/active-clients-interface";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../app.config";


@Injectable({
    providedIn: 'root'
})

export class DashboardService{
    clients: ActiveClientsInterface;

    apiVersion = `v${AppConfig.data['apiVersion']}`;
    endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`;
    
    constructor(private http: HttpClient) {

    }

    AppConfig = {
        endpoint: "http://api.mmcafe.corp/license",
        apiVersion: 1
    }

    getClients(): Observable<ActiveClientsInterface> {        
        return this.http.get<ActiveClientsInterface>(`${this.endpoint}/clients`);
    }   


    _do_get(path: string){
        
    }

    _do_post(path: string) {

    }
}