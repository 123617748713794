import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

import { combineLatest, combineLatestWith } from 'rxjs/operators';
import { firstValueFrom, lastValueFrom } from 'rxjs';

@Injectable()
export class AppConfig {
    public static data;
    public static git;
    constructor(private http: HttpClient) {
    }
    public load() {
      let versionate =  lastValueFrom(
        this.http.get('assets/git-version.json')
        .pipe(combineLatestWith(this.http.get('assets/config.' +environment.endpoint+ '.json')))
        ).then((version) => {AppConfig.data = Object.assign({}, version[0], version[1])})

return versionate

    }
}
