import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user';
import { AppConfig } from '../app.config';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class AuthService {

  apiVersion = `v${AppConfig.data['apiVersion']}`;
  endpoint = `${AppConfig.data['endpoint']}/${this.apiVersion}`;

  constructor(private http: HttpClient,
              private user: User) {}

  signin(data): Observable<any> {
    const credentials = {
      grant_type: 'password',
      email: data.key,
      password: data.password
    };
    return this.http.post<any>(`${this.endpoint}/oauth/token`, credentials).pipe(tap( (response:any) => {
      this.user.saveToken(response.access_token);
    }));
  }

  signout(): Observable<any> {
    return this.http.post<any>(`${this.endpoint}/oauth/revoke`, this.user.getToken())
    .pipe(
      map( response => {
        this.user.deleteToken();
      })
    )
  }

  forgotPassword(data) {
    const user = {
      user: {
        email: data.email
      }
    };
    return this.http.post<any>(`${this.endpoint}/users/forgot_password`, user);
  }

  resetPassword(data, token) {
    const user = {
      user: {
        reset_password_token: token,
        password: data.password,
        password_confirmation: data.confirm_password
      }
    };
    return this.http.post<any>(`${this.endpoint}/users/reset_password`, user);
  }


  info(): Observable<any> {
    return this.http.get<any>(`${this.endpoint}/oauth/token/info`);
  }

}
