import { Component, OnInit, ViewContainerRef, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { ClientService } from '../../../services/client.service';
import { Client } from '../../../interfaces/cliente-interface';
import { AlertController } from 'mm-ui';
import { ReasonsService } from '../../../services/reasons.service';
import { BlockEvents } from '../../../interfaces/blockEvents';
import { dateMask } from "../../../utils/masks";
import { MessageService } from 'primeng/api';



@Component({
  selector: 'mm-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class ClientViewComponent implements OnInit {


  fielddatemask = new UntypedFormControl('');
  form: UntypedFormGroup;
  id: string;
  dateMask = dateMask

  @Input() userInfo: Array<Client> = [];
  @Input() blockEvents: Array<BlockEvents> = [];

  altered: boolean = false;

  reasonsList: Array<any> = [];

  alertClass: string;
  alertText: string;

  forms: UntypedFormArray = this.formBuilder.array([]);
  keyValueConfig: any[];
  hasAlice = false;

  constructor(
    private vcr: ViewContainerRef,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private reasonsService: ReasonsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) { }


  ngOnInit() {
    this.altered = this.route.snapshot.params['altered'];
    if (this.altered) {
      this.alertClass = "success";
      this.alertText = "Dados do cliente atualizados com sucesso"
    }
    this.id = this.route.snapshot.params['id'];
    this.clientService.get(this.id)
      .subscribe(user => {
        this.userInfo.push(user);
        this.blockEvents.push(user.blockEvents.pop());

        this.hasAlice = !!user.modules
          .find(m => m.module.name === 'alice' && m.module.available)

        if (this.hasAlice) {
          this.clientService.getAliceKeyValueConfig(this.id).subscribe((response) => {
            this.keyValueConfig = response;
            response.forEach((config) => {
              this.forms.push(this.formBuilder.group({
                id: config.id,
                name: [config.name, Validators.required],
                value: [config.value, Validators.required],
                type: [config.type, Validators.required]
              }));
            });
          });
        }
      })

    this.reasonsService.reasonsList()
      .subscribe(reasons => this.reasonsList.push(reasons.content))

  }


  blockClient(event) {

    event.preventDefault();


    this.alertController.configure({
      showSelectTag: true,
      selectOptions: this.reasonsList[0],
      title: `Bloquear Cliente ${this.userInfo[0].name}`,
      message: `<p>Você tem certeza que deseja bloquear este cliente?</p>

<p>Lembre-se o bloqueio completo interrompe todas as sessões de usuários logados e bloqueia a utilização de todos os serviços da ferramenta.</p>

<p>Para que este cliente seja bloqueado, você deve listar um motivo para realizar essa ação.</p>`,
      buttons: [
        {
          text: 'Bloquear',
          type: 'primary',
          handler: () => {
            let data = {}
            const reasons: HTMLSelectElement = document.querySelector('#alert-select');
            const reasonId = reasons.options[reasons.selectedIndex].value;

            this.clientService.totalBlock(this.id, reasonId)
              .subscribe(status => {
                this.userInfo[0].totalBlock = status.totalBlock;
                this.blockEvents = [status.blockEvents.pop()];
                this.altered = true;
                this.alertClass = 'success';
                this.alertText = 'Usuário bloqueado com sucesso'

              })

          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            console.log('closed');
          },
          type: 'outline-dark'
        }
      ]
    });
    this.alertController.open();

  }

  unblockClient(event) {

    event.preventDefault();

    this.alertController.configure({
      showSelectTag: true,
      selectOptions: this.reasonsList[0],
      title: `Desbloquear Cliente ${this.userInfo[0].name}`,
      message: `
<p>Você tem certeza que deseja desbloquear este cliente?</p>

<p>Lembre-se o desbloqueio completo ativa todas as sessões de usuários logados e libera a utilização de todos os serviços da ferramenta.</p>

<p>Para que este cliente seja desbloqueado, você deve listar um motivo para realizar essa ação.</p>
`,
      buttons: [
        {
          text: 'Desbloquear',
          type: 'primary',
          handler: () => {

            const reasons: HTMLSelectElement = document.querySelector('#alert-select');
            const reasonId = reasons.options[reasons.selectedIndex].value;
            this.userInfo[0].totalBlock = false;
            this.clientService.totalUnblock(this.id, reasonId)
              .subscribe(status => {
                this.altered = true;
                this.alertClass = 'success';
                this.alertText = 'Usuário desbloqueado com sucesso';
              })

          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            console.log('closed');
          }
        }
      ]

    });
    this.alertController.open();

  }

  indexUsers() {
    this.clientService.reindexClientUsers(this.id)
      .subscribe(res => {

        this.altered = true;
        this.alertClass = 'success';
        this.alertText = 'Usuários indexados com sucesso';
      }, err => {
        this.alertClass = 'danger';
        this.alertText = 'Não foi possível indexar os usuários';
      })
  }

  indexCMS() {
    this.clientService.reindexClientPageAndMedias(this.id)
      .subscribe(res => {
        this.altered = true;
        this.alertClass = 'success';
        this.alertText = 'Objetos indexados com sucesso';
      }, err => {
        this.alertClass = 'danger';
        this.alertText = 'Não foi possível indexar os objetos';
      })
  }

  cleanCache() {
    this.clientService.cleanCache(this.id)
      .subscribe(res => {
        this.altered = true;
        this.alertClass = 'success';
        this.alertText = 'Cache limpo com sucesso';
      }, err => {
        this.alertClass = 'danger';
        this.alertText = 'Não foi possível limpar o cache';
      })
  }

  deleteMedias() {
    this.clientService.deleteMediasDefinitively(this.id)
      .subscribe({
        next: () => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Midias removidas com sucesso';
        },
        error: () => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível remover as mídias';
        }
      })
  }
  createEmbeddingIndex() {
    this.clientService.createEmbeddingIndex(this.id)
      .subscribe({
        next: () => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Índice de embedding criado com sucesso';
        },
        error: () => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível criar o índice de embedding';
        }
      })
  }

  addNew() {
    this.forms.insert(0, this.formBuilder.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
      type: ['SYSTEM_MESSAGE', Validators.required]
    }));
  }

  removeForm(i: number) {
    const form = this.forms.at(i).value;

    if (form.id) {
      this.clientService.aliceDeleteKeyConfig(form.id).subscribe({
        next: () => {
          this.forms.removeAt(i);
          this.messageService.add({ severity: 'success', summary: 'Configuração removida com sucesso', detail: '' });
        },
        error: () => this.messageService.add({ severity: 'error', summary: 'Erro ao remover configuração', detail: '' })
      });
    } else {
      this.forms.removeAt(i);
      return;
    }
  }

  saveConfig() {
    this.clientService.saveAliceKeyValueConfig(this.forms.value, this.id).subscribe({
      next: () => this.messageService.add({ severity: 'success', summary: 'Configurações salvas com sucesso', detail: '' }),
      error: () => this.messageService.add({ severity: 'error', summary: 'Erro ao salvar configurações', detail: '' })
    });
  }
}
