import { Injectable, NgModule} from '@angular/core';
import { Observable } from 'rxjs';
// import 'rxjs/add/operator/do';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { User } from '../models/user';
import { AlertController } from 'mm-ui';
import { tap } from 'rxjs/operators';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

  constructor(private user: User) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.user.isAuthenticated()) {

      const dupReq = req.clone(
        //headers: req.headers.set('Authorization', 'Bearer ' + this.user.getToken())
        { setHeaders:
          {
            'Access-Control-Allow-Origin':  '*',
            'Authorization': 'Bearer ' + this.user.getToken(),
            'Content-Type':  'application/json',
          }
        }
      );

        return next.handle(dupReq);
    }
    return next.handle(req);
  }
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private user: User, private router: Router, private alertController: AlertController) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {

        const status = err.status;

        if (status === 401) {
          if (this.router.url.indexOf('/clients') >= 0) {
            this.user.deleteToken();
            localStorage.setItem('redirectTo', this.router.url);
            this.alertController.configure({
              error: 'login'
            })
          this.alertController.open();
            this.router.navigate(['/login'], { queryParams: { signedout: true } });
          }
        } else if (status === 0) {
          this.router.navigate(['/error', status], { skipLocationChange: true });
        } else {
          console.error(err);
        }
      }
    }));
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
  ]
})
export class InterceptorModule { }

