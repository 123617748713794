<div class="container">
<mmp5-breadcrumb></mmp5-breadcrumb>

<section class="mm-box-container generalData">
  <div class="container-border--companyName" *ngFor="let user of userInfo">
    <header class="mm-box-header">{{user.companyName}}</header>

    <div class="row">

      <div class="col">
        <div *ngIf="altered" class="alert-adjust">
          <mm-alert [type]="alertClass" [text]="alertText"></mm-alert>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div class="box-data--adjust box-clienteatv">
          <div class="box-clienteatv--margin" *ngIf="!user?.licenseValidatedDate; else noLicenseValidated">
            <p><strong> Chave de acesso deste cliente:</strong> {{user.licenseKey}}</p>
            <p *ngIf="user.activationLink"><strong> Chave de ativação: {{user.activationLink}} </strong></p>
          </div>
          <ng-template #noLicenseValidated>
            <div class="box-clienteatv--margin">
              <p><strong> Esta conta de cliente foi verificada em </strong> {{user?.licenseValidatedDate | date:
                'dd/MM/yyyy'}}</p>
            </div>
          </ng-template>

          <div class="client-status" *ngIf="!user.totalBlock; else elseNoActive">
            <div class="client-status--adjuststyle">
              <p>
                <!-- <i class="fa fa-check-circle faicon-checkcircle"> </i>  -->
                <mmp5-mm-icons class="faicon-checkcircle" [icon]="['fa-solid','fa-circle-check']"></mmp5-mm-icons>
                O Status deste cliente é <strong>Ativo</strong>
              </p>
            </div>

            <div class="text-right text-right--btn">
              <button class="btn btn-primary" (click)="blockClient($event)">Efetuar bloqueio imediato</button>

              <mmp-tooltip
                [text]="'Atenção: O bloqueio completo interrompe todas as seções de usuários logados e bloqueia a utilização de todos os serviços da ferramenta.'"
                [tooltipClass]="'tooltip-default'"
                [ariaLabel]="'Ícone que mostra uma caixa de texto'"
                [tooltipPosition]="'above'" [class]="'faicon-questioncircle tooltip'"></mmp-tooltip>

            </div>
          </div>

        </div>

        <ng-template #elseNoActive>
          <div class="row client-status" *ngFor="let block of blockEvents">
            <div class="col-md-8">
              <p>
                <!-- <i class="fa fa-exclamation-triangle"> </i> -->
                <mmp5-mm-icons class="exclamation-triangle" [icon]="['fa-solid','fa-triangle-exclamation']"></mmp5-mm-icons>
                O status deste cliente é: <strong> BLOQUEADO</strong> Motivo: {{block.reason.description}} | Data do
                bloqueio: {{block.creationDate | date: 'dd/MM/yyyy'}}
              </p>
            </div>
            <div class="col-md-4 text-right text-right--btn">
              <button class="btn btn-primary" (click)="unblockClient($event)">Desbloquear imediatamente</button>
              <mmp-tooltip [text]="'Atenção: o desbloqueio completo ativa todas as sessões de usuários logados e permite a utilização de todos os serviços da ferramenta.'"
                [tooltipClass]="'tooltip-default'" [ariaLabel]="'Ícone que mostra uma caixa de texto'"
                [tooltipPosition]="'above'" [class]="'faicon-questioncircle tooltip'"></mmp-tooltip>
            </div>
          </div>
        </ng-template>

        <div class="box-data-section box-data--padding">
          <div class="row">
            <div class="col-md-7">
              <div class="box-data">
                <h5>Dados da Empresa</h5>

                <div class="box-data--company">
                  <p class="p-strong"><strong>Nome do Cliente</strong></p>
                  <p>{{user.companyName}}</p>

                  <p class="p-strong"><strong>CNPJ</strong></p>
                  <p>{{user.cnpj}}</p>

                  <p class="p-strong"><strong>Tipo de Licenciamento</strong></p>
                  <p>{{user.licenseType | licence}}</p>

                  <p class="p-strong"><strong>Quantidade de Licenças contratadas</strong></p>
                  <p>Mínimo: {{user.qtMin}} Máximo: {{user.qtMax}} - Bloqueio parcial:
                    <span *ngIf="user.autoPartialBlock; else elseNo"> Sim</span>
                    <ng-template #elseNo><span> Não</span></ng-template>
                  </p>

                  <p class="p-strong"><strong>Vigência do Contrato</strong></p>
                  <p>
                    Início: {{user.initialDate | date: 'dd/MM/yyyy'}}
                    Término: {{user.finalDate | date: 'dd/MM/yyyy'}}
                  </p>
                  <p class="p-strong"><strong>Observação</strong></p>
                  <p>{{user?.observations}}</p>
                </div>

              </div>
            </div>

            <div class="col-md">
              <div class="box-data">
                <h5>Contatos do Responsável</h5>

                <div class="box-data--contact">
                  <p class="p-strong"><strong>Nome do contato responsável</strong></p>
                  <p>{{user.name}}</p>
                  <p class="p-strong"><strong>E-mail do contato do responsável</strong></p>
                  <p>{{user.email}}</p>
                  <p class="p-strong"><strong>Telefone do contato do responsável</strong></p>
                  <p>{{user.phone}}</p>
                </div>
              </div>

              <div class="box-data box-data--report">
                <h5>Contatos para Relatórios</h5>

                <div class="box-data--contact">
                  <div *ngFor="let mail of user.reportEmails">
                    <p class="p-strong"><strong>E-mail do contato responsável</strong></p>
                    <p>{{mail.email}}</p>

                    <p class="p-strong"><strong>Periodicidade do envio de relatórios</strong></p>
                    <p>{{mail.type | reportTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content" *ngIf="user?.modules.length > 0">
            <mm-list-modules #modules [activeClientsModules]="user?.modules" [type]="'view'">
            </mm-list-modules>
          </div>

          <div class="d-flex justify-content-between mb-3">
            <div>
              <a routerLink="/clients/edit/{{user?.id}}" class="btn btn-primary">
                Editar Cliente
              </a>
            </div>
            <div>
              <button (click)="createEmbeddingIndex()" class="btn btn-primary ms-2">Criar index vetorial (Alice)</button>
              <button (click)="deleteMedias()" class="btn btn-primary ms-2"> Excluir mídias</button>
              <button (click)="indexUsers()" class="btn btn-primary ms-2"> Indexar usuários</button>
              <button (click)="indexCMS()" class="btn btn-primary ms-2"> Indexar CMS</button>
              <button (click)="cleanCache()" class="btn btn-primary ms-2"> Limpar cache</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="mm-box-container alice" *ngIf="hasAlice">
  <header class="mm-box-header">Configurações de Chaves e Valores (Alice)</header>
  <div class="mm-box-content">
   <p>{{ 'alice.config.keyValueConfigDesc' | translate }}</p>
    <h3>{{ 'alice.config.type' | translate }}</h3>
    <p>{{ 'alice.config.typeDesc' | translate }}</p>
    <ul>
      <li><strong>{{ 'alice.config.systemMessage' | translate }}</strong>: {{ 'alice.config.systemMessageDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.externalConnector' | translate }}</strong>: {{ 'alice.config.externalConnectorDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.model' | translate }}</strong>: {{ 'alice.config.modelDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.embedding' | translate }}</strong>: {{ 'alice.config.embeddingDesc' | translate }}</li>
    </ul>
    <h3>{{ 'alice.config.parameters' | translate }}</h3>
    <ul>
      <li><strong>{{ 'alice.config.name' | translate }}</strong>: {{ 'alice.config.parametersKey' | translate }}</li>
      <li><strong>{{ 'alice.config.value' | translate }}</strong>: {{ 'alice.config.parametersValue' | translate }}</li>
    </ul>
   <mm-alice-keys-config [type]="'LICENSE'" [forms]="forms" (addNew)="addNew()" (removeForm)="removeForm($event)"></mm-alice-keys-config>
  </div>
  <section class="mm-box-footer d-flex justify-content-end">
    <button class="btn btn-primary" (click)="saveConfig()">Salvar Chaves</button>
  </section>
</section>

<section class="mm-box-container generalData">
  <header class="mm-box-header">Relatório de consumo</header>
  <div class="mm-box-content">
    <div class="row container-border--report--adjust">
      <div class="col-3 ps-0 report-calendar">
        <mmp5-fieldset-haruki [formControl]="fielddatemask" [type]="'date'" [key]="'date'" [label]="'Data inicial'"
          [mask]="dateMask"></mmp5-fieldset-haruki>
      </div>
      <div class="col-1 d-flex align-items-center justify-content-center">
        <p class="strong"> Até </p>
      </div>
      <div class="col-3 report-calendar">
        <mmp5-fieldset-haruki [formControl]="fielddatemask" [type]="'date'" [key]="'date-until'" [label]="'Data final'"
          [mask]="dateMask"></mmp5-fieldset-haruki>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-primary btn-">Baixar Relatório</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="container margin-border card-section">
<div class="row card-style" *ngFor="let user of userInfo">
  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container totalUsers">
      <header class="mm-box-header">
        <h6 class="m-0">Usuários logados</h6>
      </header>
      <div class="mm-box-content text-center">
        <div class="mm-box-content">

          <div class="container">
            <p>{{user.totalCurrentSessions}}</p>
          </div>

        </div>
      </div>
    </section>
  </div>

  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container">
      <header class="mm-box-header">
        <h6 class="m-0">Clientes ativos</h6>
      </header>
      <div class="mm-box-content">
        <div class="container">
          <mmp5-graph></mmp5-graph>
        </div>
      </div>
    </section>
  </div>

  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container">
      <header class="mm-box-header">
        <h6 class="m-0">Clientes ativos</h6>
      </header>
      <div class="mm-box-content">
        <div class="container">
          <mmp5-graph></mmp5-graph>
        </div>
      </div>
    </section>
  </div>
</div>
